.banner {
  background-image: url("./Images1/banner10.jpg");
  background-size: cover;
  background-position: center center;
  height: 300px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  margin-top: -75px;
}
.section {
  text-align: justify;
}
.img-responsive {
  vertical-align: middle;
  margin-top: 55px;
  width: 100%;
  height: 350px;
}
.heading .growheading {
  text-align: center;
  color: white;
  padding: 94px;
}
.text-center {
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .banner {
    background-position: center top;
  }
}
