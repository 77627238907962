.banner {
  background-image: url("./Images1/banner10.jpg");
  background-size: cover;
  background-position: center center;
  height: 300px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  margin-top: -75px;
}
.section {
  text-align: justify;
}
.menu {
  align-content: start;
  text-align: start;
}
.img-responsive {
  vertical-align: middle;
  margin-top: 87px;
  width: 100%;
  height: 350px;
  padding-left: 4rem;
}
.heading .growheading {
  text-align: center;
  color: white;
  padding: 94px;
}
.text-center {
  margin-top: 4rem;
}
.content {
  margin-top: 4rem;
  padding-left: 40px;
}
.heading h1
 {
  text-align: center;
  color: white;
  padding: 94px;
}
@media (max-width: 768px) {
  .banner {
    background-size: contain;
    background-position: center top;
    width: 100%;

    margin-bottom: -205px;
  }
  .heading .grows {
    text-align: center;

    color: white;
    padding-top: 10px;
    size: 0.5rem;
  }

  .text-center {
    margin-top: 62px;
  }
  .content {
    margin-top: 4rem;

    padding-left: 0px;
  }
  .img-responsive {
    margin-top: 1rem;
  }
}
