.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  height: 600px;
}

.boxes {
  margin-left: 400px;
  display: flex;
}

.contact-box {
  width: 200px;
  height: 150px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  transform: translate(-50%, -50%);
  margin-top: -490px;
  flex-basis: calc(50% - 10px);
  border: 1px solid grey;
}

.icon {
  color: skyblue;
  font-size: 24px;
  margin-bottom: 10px;
}

.icon i {
  vertical-align: middle;
}

.contact-box h2 {
  margin: 0;
  font-size: 14px;
}

.heading {
  margin-top: 50px;
  text-align: center;
  font-size: 2rem;
}

.contact-box p {
  margin: 0;
  font-size: 12px;
}

.contact-form {
  width: 575px;
  background-color: lightblue;
  position: relative;
  margin-top: 100px;
  margin-left: 430px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 400px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.contact-button {
  width: 150px;
  padding: 10px;
  background-color:#2196f3;
  border: none;
  cursor: pointer;
  margin-bottom: -10px;
  color: #fff;
  font-weight: 600;
}

.submission-status {
  color: green;
  margin-bottom: -40px;
}

@media (max-width: 768px) {
  .contact-container {
    height: auto;
    justify-content: center;
    align-items: center;
  }

  .boxes {
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    margin-left: 155px;
  }

  .contact-box {
    width: 90%;
    margin: 10px 0;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  .contact-form {
    margin: 20px auto;
    padding: 10px;
    width: 100%;
    background-color: lightblue;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 110%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .map-container {
    width: 80%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
}
