/* ***********************home part-1*************** */
.content-part{
    margin-top:3rem;
  }
  .content-heading{
    color: rgba(0,0,0,.54);
    font-weight: 700 !important;
    font-size: 23px;
  }
  .btn-primary1{
    margin-right: 20px;
    padding: 8px 28px !important;
    font-weight: 700 !important;
    background-color: #2196f3 !important;
    border: 1px solid #2196f3 !important;
    margin-top: 20px;
    width: 200px;
 }
  .btn-primary2{
    padding: 8px 28px !important;
    font-weight: 700 !important;
    background-color: #fff!important;
    color: #2196f3 !important;
    border: 1px solid #2196f3 !important;
    margin-top: 20px;
    width: 200px;
 
 }
 .image{
  width:100%;
  height: auto;
 }
 .image .responsive-image {
  width:100%;
  height: auto;
}
@media screen and (max-width: 767px) {
  .responsive-image {
    margin-top: 20px !important;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
   }
}
@media screen and (min-width: 768px) {
  .responsive-image {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .content-part{
    margin-top:20px;
  }
}
.slider-btn{
  border: none !important;
  background-color:#fff !important;
  color: black !important;
  font-weight:bold !important;
  font-size: 25px !important;
}
.activeSlide{
 color: #ffc107 !important;
 border: 1px solid #f3be1f !important;
 padding: 10px 40px !important;
}
@media screen and (max-width: 767px) {
  .activeSlide{
    color: #ffc107 !important;
    border: 1px solid #f3be1f !important;
    padding: 2px 10px !important;
   }
}

@media screen and (max-width: 576px) {
  .btn-primary1 ,.btn-primary2{
    width: 100%;
  }
}

/* ****************part-2****************** */
.content-part1{
  margin-top: 7rem;
}
@media screen and (max-width: 767px) {
  .content-part1{
    margin-top:20px;
  }
}
@media screen and (max-width: 991px) {
  .puzzle{
   display:none
  }
}
@media screen and (min-width: 992px) {
  .puzzle{
    position: relative;
    height: 100%;
  }
  .deco-1{
    left: 0;
    top: -46px;
    opacity: .3;
    border-bottom: 156px solid;
    border-right: 160px solid transparent;
    position: absolute;
    border-bottom-color:#b2ebf2;
  }
  .deco-2{
    height: 250px;
    width: 300px;
    left: 150px;
    top: 30px;
    border-top-right-radius: 100px;
    position: absolute;
    border: 10px solid #2196f3;
  }
  .deco-3{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    right: 230px;
    top: 50px;
    border: 8px solid #ffc107;
    position: absolute;
  }
  .deco-4{
    right: 220px;
    top: 220px;
    border-bottom: 130px solid #00bcd4;
    border-left: 130px solid transparent;
    position: absolute;
  }
  .deco-5{
    width: 48px;
    height: 48px;
    left: 80px;
    bottom: -120px;
    opacity: .1;
    background: #2196f3;
    position: absolute;
  }
  
  .big{
    width: 290px;
    top: 110px;
    left:120px;
  }
  .medium{
    width: 130px;
    height: 130px;
    left: 280px;
    top: -40px;
    border-radius: 50%;
  }
  .small{
    width: 98px;
    height: 98px;
    top: -20px;
    left: 40px;
    border-radius: 50%;
  }
  .img1{
    position: absolute;
    overflow: hidden;  
  }
}

@media screen and (min-width: 1199px) {
  .puzzle{
    position: relative;
    height: 100%;
  }
  .deco-1{
    left: 0;
    top: -46px;
    opacity: .3;
    border-bottom: 156px solid;
    border-right: 160px solid transparent;
    position: absolute;
    border-bottom-color:#b2ebf2;
  }
  .deco-2{
    height: 250px;
    width: 300px;
    left: 200px;
    top: 30px;
    border-top-right-radius: 100px;
    position: absolute;
    border: 10px solid #2196f3;
  }
  .deco-3{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    right: 230px;
    top: 50px;
    border: 8px solid #ffc107;
    position: absolute;
  }
  .deco-4{
    right: 220px;
    top: 220px;
    border-bottom: 130px solid #00bcd4;
    border-left: 130px solid transparent;
    position: absolute;
  }
  .deco-5{
    width: 48px;
    height: 48px;
    left: 80px;
    bottom: -120px;
    opacity: .1;
    background: #2196f3;
    position: absolute;
  }
  
  .big{
    width: 290px;
    top: 110px;
    left:120px;
  }
  .medium{
    width: 130px;
    height: 130px;
    left: 280px;
    top: -40px;
    border-radius: 50%;
  }
  .small{
    width: 98px;
    height: 98px;
    top: -20px;
    left: 40px;
    border-radius: 50%;
  }
  .img1{
    position: absolute;
    overflow: hidden;  
  }
}

.paragraph1{
  margin-top: 20px;
  font-weight: 500;
}

.horizontal-line1 {
  position: relative;
  display: inline-block;
  margin-top: 20px;
  font-weight: 700;
}

.horizontal-line1::after {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 60%;
  transform: translateX(-50%);
  width: 50px;
  height: 8px;
  background-color: #2196f3;
}
.horizontal-line2 {
  position: relative;
  display: inline-block;
  margin-top: 20px;
  font-weight: 700;
}

.horizontal-line2::after {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 60%;
  transform: translateX(-50%);
  width: 50px;
  height: 8px;
  background-color: #ffc107;
}
.para-subtitle{
  margin-top: 30px;
  font-size: 22px;
  text-transform: uppercase;
}

/* ************************part-3******************* */
.content-part2{
  margin-top: 10rem;
}
@media screen and (max-width: 991px) {
  .content-part2{
    margin-top: 20px;
  }
}
.about-heading{
 font-weight: 700; 
}

.about-horizontal-line{
    width: 70px;
    height: 12px;
    border-radius: 12px;
    background-color:#2196f3;
}
.paragraph2{
  margin-top: 20px;
  font-size:18px;
  line-height: 30px;
  font-weight: 500;
}
.btn-primary3{
  margin-top: 20px;
  padding: 8px 28px !important;
  font-weight: 700 !important;
  background-color: #ffc107 !important;
  border: 1px solid #ffc107 !important;
}
/* *******************part-4**************** */
.content-part3{
  margin-top:5rem;
}

.services-card {
  box-shadow: 0 1.5px 12px 2px rgba(0,0,0,.06);
  border: 1px solid #ffc107;
  margin-top: 16px;
  position: relative;
  width: 100%;
}

.services-card figure{
  top: -30px;
  margin: 0 16px -16px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  display: block;
  height: 150px;
}
.services-card figure img {
  width: 100%;
  min-height: 100%;
}
.services-heading{
  font-weight: 700;
}
.services-horizontal-line{
  width: 70px;
  height: 12px;
  border-radius: 12px;
  background-color:#2196f3;
  margin: 20px auto;
}
.services-content{
  padding:20px;
}
.services-paragraph{
  font-weight: 500;
}
.services-btn{
  padding: 8px 28px !important;
  font-weight: 500 !important;
  background-color: #fff!important;
  color: #0d47a1 !important;
  border: 1px solid #2196f3 !important;
  width: 100% !important;
}

/* *****************part-5****************** */
.testimonial{
  position: relative;
  margin-top: 50px;
}
.testimonial-heading{
  font-weight: 700;
}
.paper-card{
  border-radius: 20px;
  box-shadow: 0 1.5px 12px 2px rgba(0,0,0,.06);
  border-color:#2196f3 !important;
  margin: 24px 0;
  padding: 60px 120px;
  height: 280px;
  text-align: center;
  border: 2px solid transparent;
  transition: border .4s ease-out;
  position: relative;
}
.avatar{
  margin: -120px auto 0;
  border-radius: 50%!important;
  box-shadow: 0 4px 5px -2px hsla(0,0%,50.2%,.2), 0 7px 10px 1px hsla(0,0%,50.2%,.14), 0 2px 16px 1px hsla(0,0%,50.2%,.12);
  box-sizing: content-box;
  overflow: visible;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  height: 80px;
  min-width: 80px;
  width: 80px;
}
.avatar img{
  border-radius: inherit;
  display: inline-flex;
  height: inherit;
  width: inherit;
}
 .slider-caption{
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #ffc107 ;
}
.slider-para1{
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  height: auto;
  font-weight: 500;
  font-size: 15px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.testimonials-horizontal-line{
  width: 100px;
  height: 12px;
  border-radius: 12px;
  background-color:#2196f3;
  margin: 20px auto;
}
@media screen and (max-width: 768px) {
  .testimonial-part{
    display: none;
  }
}

/* **************part-6**************** */
.get-in-touch{
  position: relative;
}
.get-in-touch1{
  background-image: linear-gradient(95deg,#03acf2,#c8e6c9 130%);
  box-shadow: 0 1.5px 12px 2px rgba(0,0,0,.06);
  padding: 50px 40px;
  color: #fff;
}
.get-in-touch-btn{
  font-size: 20px !important;
  color: #03acf2 !important;
  border-radius: 36px !important;
  font-weight: 600 !important;
  background-color: #fff !important;
  border: none !important;
  padding: 10px 40px !important;
}