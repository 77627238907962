.services-header {
  color: #333;
  margin-right: 10px;
}
.service-header {
  color: #333;
  margin-left: 120px;
  margin-bottom: -40px;
  margin-top: 20px;
}

.intro-text {
  font-size: 1.2rem;
  margin: 0 auto 30px;
  max-width: 600px;
  color: #777;
}

.first-container {
  padding: 50px;
  background-color: #f8f9fa;
  text-align: center;
}

.second-container {
  background-color: #f8f9fa;
  position: relative;
  z-index: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-title {
  justify-content: center;
  margin-bottom: 10px;
}

.section {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 15px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  transform-origin: center center;
}

.icon {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #007bff;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}
.third-container {
  padding: 50px;
  background-color: #ecf4ff;
  text-align: center;
}

.fourth-container {
  padding: 50px;
  background-color: #f8f9fa;
  text-align: center;
  background-image: none;
}
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 15px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: 1px solid lightskyblue;
}

.count {
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-weight: bold;
  margin-bottom: 10px;
}

h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #333;
}

.service-card {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  margin-left: 30px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.service-card.animate {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.box {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.box.animate {
  opacity: 1;
  transform: scale(1);
}
.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.card-title {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.card-text {
  text-align: center;
  margin-top: 10px;
}
.service-card img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .sections {
    flex-direction: column;
    align-items: center;
  }

  .section {
    width: 100%;
  }

  .sticky-image {
    top: 0;
    height: 150px;
  }

  .between-image {
    height: 150px;
  }
  .sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .service-card {
    margin: 2px;
    text-align: center;
  }

  .sections1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .between-image {
    background-image: none;
  }
  .first-container {
    padding-left: 0%;
    margin-bottom: 20px;
  }
  .second-container {
    margin-top: 0px;
    padding-left: 0px;
    margin-left: 0px;
    align-items: center;
  }
  .services-header,
  .service-header {
    text-align: center;
    margin: 20px 0;
  }

  .intro-text {
    font-size: 1rem;
    max-width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
  }
  .first-container,
  .second-container,
  .third-container,
  .fourth-container {
    padding: 20px 10px;
  }
  .service-card {
    margin: 0 auto 20px;
  }
  .sections,
  .sections1 {
    flex-direction: column;
    align-items: center;
  }

  .box {
    margin: 10px 0;
  }
  .between-image {
    height: 150px;
  }
}
