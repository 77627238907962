.header {
    padding: 10px;
    background-color: white;
    box-shadow: -1px 4px 4px 0px rgb(0 0 0 / 15%);
    border: 1px solid #fff !important;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    
  }
  .navbar .navbar-brand {
    color: #2196f3;
    font-weight: 700;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: black;
  }
  
  ul.navbar-nav li {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    padding-right: 10px;
  }
  
  .active {
    color: #2196f3 !important;
  }
  
  
  /* ************footer************** */
  .ant-layout-footer {
    background: none;
  }
  .footer-part {
    position: relative;
    padding-bottom: 150px;
    margin-top: 5rem;
  }
  .footer-part-deco {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    bottom: 0;
    left: 0;
    background: url(./imgs/footer-deco.svg) repeat-x bottom;
  }
  .footer-heading{
  color: #2196f3;
  font-weight: 700;
  }
  .footer-paragraph{
    font-weight: 600;
  }
  
  ul.footer-contact li a{
    text-decoration: none;
    color: black;
    font-size: 500;
    font-weight: 600;
  }
  .footer-icon{
    padding-right: 15px;
    color: #2196f3;
  }
  .social-icon{
    color: #fff;
  }
  .social-link a{
    display: inline-block; 
    background-color:#4285f4; 
    height:30px;
    width:30px;
    border-radius:50%;
    line-height: 30px;
    text-align: center;
    margin: 5px;
  }
  .footer-text{
    color: #ffc107;
    font-size: 18px;
    font-weight: 700;
  }
  
  a .footer-text{
    text-decoration: none !important;
  }
  
   